import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useReactiveVar } from '@apollo/client';
import { useEffect } from 'react';
import styled from 'styled-components';

import { isLoggedInVar } from 'lib/apollo/apollo';
import useScrollToTop from 'views/Community/Board/hooks/Common/scrollToTop';
import usePageHistory from 'hooks/Common/usePageHistory';

const LogisticToolsContainerAdaptive = styled.section`
  min-width: 1500px;
  width: 100%;
  min-height: 800px;
  display: flex;
  margin-left: 84px;
  background-color: #fff;
`;

interface LogisticToolsContainerAdaptiveLayoutProps extends RouteComponentProps {
  children: React.ReactNode;
}

function LogisticToolsContainerAdaptiveLayout({ children }: LogisticToolsContainerAdaptiveLayoutProps) {
  const isLoggedIn = useReactiveVar(isLoggedInVar);
  const { history } = usePageHistory();

  useScrollToTop();

  useEffect(() => {
    if (!isLoggedIn) {
      if (history.location.pathname !== '/logistic-tools/main') {
        history.replace('/logistic-tools/main');
        return;
      }
    }
  }, [history, isLoggedIn]);

  return <LogisticToolsContainerAdaptive>{children}</LogisticToolsContainerAdaptive>;
}

export default withRouter(LogisticToolsContainerAdaptiveLayout);
