import { Dispatch, SetStateAction, useState } from 'react';
import styled from 'styled-components';

import { headerLogo } from 'assets/image';
import LazyImage from 'components/common/LazyImage';
import useMemoCookies from 'hooks/Common/useMemoCookies';
import usePageHistory from 'hooks/Common/usePageHistory';

export function UpdateNoticePop({
  close,
  cookieName,
}: {
  close: Dispatch<SetStateAction<boolean>>;
  cookieName: string;
}) {
  const memoCookies = useMemoCookies();

  const [currentCheckDay, setCurrentCheckDay] = useState<number | null>(null);

  const { history } = usePageHistory();

  // const isLoggedIn = useReactiveVar(isLoggedInVar);

  const handleCloseToday = () => {
    if (!currentCheckDay) {
      close(false);
      return;
    }

    memoCookies.set(cookieName, cookieName, {
      path: '/',
      // expires: DateTime.now().plus({ days: 1 }).toJSDate(),
      maxAge: 24 * 60 * 60 * currentCheckDay,
      sameSite: 'lax',
    });
    close(false);
  };

  return (
    <div className="absolute top-[50vh] -translate-y-1/2 left-1/2 -translate-x-1/2 z-[55] bg-white shadow-mypage">
      <div className=" bg-release_paymentpop-bg-black  bg-cover text-white w-[950px] h-[756px] flex  ">
        <div className="w-full">
          <div className="absolute top-[84px] left-[70px]">
            <LazyImage src={headerLogo} width={216} alt="larchive-logo" />
          </div>
          <div className="mt-[188px] mx-[30px] w-[52px] border-t-[4px] border-solid border-white"></div>
          <div className="text-[20px] mx-[30px] mt-[12px]">
            <div className="leading-[50px]">[공지: 서비스 플랜/프라이싱 전면 개편 안내]</div>
          </div>
          <div className="mx-[30px] leading-loose">
            2025년부터 라카이브의 서비스 플랜과 프라이싱이 개편되어, B2C 고객을 위한 'Basic' 플랜과 B2B 고객을 위한
            'Enterprise' 플랜으로 구분됩니다.{' '}
            <div className="inline-block cursor-pointer" onClick={() => history.push('/plan/explain')}>
              (프라이싱 페이지 바로가기)
            </div>
          </div>
          <div className="mx-[30px] text-[16px] font-light mt-[12px] leading-relaxed">
            <div>
              <div className="block font-bold">1. Basic 플랜</div>
              <div className="pl-3 font-bold "> - 대상: 개인고객(B2C)</div>
              <div className="pl-3">
                - 서비스 변경: 무료로 제공되었던 라카이브 위클리 리포트는 2025년 2월 10일부터 유료화로 전환됩니다.
                위클리 리포트는 Basic 플랜에 가입한 계정 대상으로 메일링 서비스가 제공됩니다.
              </div>
              <div className="pl-3">
                - 프로모션 종료: 기존 구독료 월 15,900원에서 조정되어 월 25,900원으로 인상됩니다. 플랫폼 운영을 위한
                최소한의 비용으로 책정했으며, 고객님의 너그러운 양해 부탁드립니다.
              </div>
            </div>
            <div className="py-3">
              <div className="font-bold">2. Enterprise 플랜</div>
              <div className="pl-3 font-bold">
                <div>- 대상: 기업고객(B2B)</div>
                <div>- 향후 프라이싱 페이지에 Enterprise 소개서가 업데이트될 예정입니다.</div>
              </div>
            </div>
            <div className="py-3">
              <div className="font-bold">3.추가 안내</div>
              <div className="pl-3">
                - 프라이싱 개편 이전에 'Basic' 연간 결제를 진행하신 구독자는 다음 연간 결제일까지 변동 이전 구독가격으로
                이용하실 수 있습니다.
              </div>
              <div className="pl-3">
                - 신규 고객은 2월 10일부터, 기존 고객은 25년 첫 번째 결제까지 프로모션 가격으로 서비스 이용 가능하며, 두
                번째 결제부터 변경된 금액으로 서비스를 이용할 수 있습니다.
              </div>
              <div className="pl-3">
                - 프라이싱 개편 이전에 'Standard' 연간 결제를 진행하신 구독자는 다음 연간 결제일까지 'Enterprise' 플랜을
                이용하실 수 있습니다.
              </div>
            </div>
          </div>
          {/* <div className="text-[20px] mx-[30px] mt-[12px]">
                <div className="leading-[50px]">[대시보드 디자인 리뉴얼 예정]</div>
              </div>
              <div className="mx-[30px] text-[16px] font-light mt-[12px] leading-[24px]">
                <div>더 나은 사용자 경험을 제공하기 위해 대시보드 디자인 리뉴얼 작업을 진행 중에 있으며,</div>
                <div>빠른 시일 내에 리뉴얼된 대시보드를 만나보실 수 있도록 최선을 다하겠습니다.</div>
              </div> */}
        </div>
        {/* <div className="pt-[40px]  h-full flex flex-col justify-between pb-[70px]">
              <div className="flex flex-col items-end justify-center pr-[80px]">
                <div className="text-[16px] font-light">단, 하나의 글로벌 공급망 인텔리전스</div>
                <GothamDiv className="text-[#78ECB6] text-[18px] leading-[40px]">See everything, Do anything</GothamDiv>
              </div>
              <div className="flex flex-col font-bold gap-4  place-self-end mr-[70px] w-[340px] h-[270px] border-[#fff] border-[4px] border-solid px-[20px] py-[21px]">
                <div className="text-[24px] leading-[30px]">안내사항</div>
                <div className="text-[16px] flex items-center gap-2 ">
                  <span className='before:content-["•"] before:mr-1'>대상</span>
                  <span className="font-thin text-[#828080] text-[13px]">|</span>
                  <span>라카이브 사용중인 모든 고객</span>
                </div>
                <div className="text-[16px] flex items-center gap-2 whitespace-nowrap ">
                  <span className='before:content-["•"] before:mr-1'>기간</span>
                  <span className="font-thin text-[#828080] text-[13px]">|</span>
                  <span>2024년 10월 28일~</span>
                </div>
                <div className="text-[16px] flex items-center gap-2">
                  <span className='before:content-["•"] before:mr-1'>내용</span>
                  <span className="font-thin text-[#828080] text-[13px]">|</span>
                  <span>팀 플랜 결제 기능 추가</span>
                </div>
                <div className="text-[16px] flex items-center gap-2 ">
                  <span className='before:content-["•"] before:mr-1'>문의</span>
                  <span className="font-thin text-[#828080] text-[13px]">|</span>
                  <span className="underline underline-offset-2">help@simplogis.com</span>
                </div>
                <div
                  onClick={handleGoPlan}
                  className="w-[260px] h-[40px] bg-white flex items-center justify-between px-5 text-black text-[14px] cursor-pointer mt-2 place-self-center"
                >
                  <span>프라이싱 페이지 가기</span>
                  <LineArrow />
                </div>
              </div> */}
        {/* </div> */}
      </div>
      <div className="h-[44px] border-[1px] border-solid border-white text-[14px] text-[#555] flex justify-between items-center px-[20px]">
        <div className="flex items-center cursor-pointer ">
          <input
            id="default-checkbox"
            type="checkbox"
            value="1"
            readOnly
            checked={currentCheckDay === 1}
            onClick={e => {
              if (e.currentTarget.checked) {
                setCurrentCheckDay(+e.currentTarget.value);
              } else {
                setCurrentCheckDay(null);
              }
            }}
            // onChange={() => {
            //   setCurre
            // }}
            className="w-4 h-4 text-white accent-baseColor focus:ring-black"
          />
          <label
            htmlFor="default-checkbox"
            className="text-sm font-medium text-gray-900 cursor-pointer ms-2 dark:text-gray-300"
          >
            하루동안 보지 않기
          </label>

          <input
            id="sevenday-checkbox"
            type="checkbox"
            value="99999999"
            readOnly
            checked={currentCheckDay === 99999999}
            onClick={e => {
              if (e.currentTarget.checked) {
                setCurrentCheckDay(+e.currentTarget.value);
              } else {
                setCurrentCheckDay(null);
              }
            }}
            // onChange={_handleCheckNoShow}
            className="w-4 h-4 ml-3 text-white accent-baseColor focus:ring-black"
          />
          <label
            htmlFor="sevenday-checkbox"
            className="text-sm font-medium text-gray-900 cursor-pointer ms-2 dark:text-gray-300"
          >
            다시 보지 않기
          </label>
        </div>
        <div className="cursor-pointer" onClick={handleCloseToday}>
          닫기
        </div>
      </div>
    </div>
  );
}

const GothamDiv = styled.div`
  font-family: 'GothamMedium';
`;
