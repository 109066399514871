import { navLarchiveIcon, navCustopiaIcon } from 'assets/image';

export interface MenuListType {
  title: string;
  url?: string;
  name: string;
  icon?: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
  newFeatureDate?: string;
  submenu?: PathType[];
}

export interface PathType {
  submenuTitle: string;
  submenuUrl: string;
  comingsoon?: boolean;
  newFeatureDate?: string;
}

export interface QubeServiceListTypes {
  name: string;
  url: string;
  icon: string;
}

export const menuList: MenuListType[] = [
  {
    name: 'dashboard',
    title: '대시보드',
    url: '/logistic-tools/main',
    newFeatureDate: '2025-02-10',
  },
  {
    name: 'analysis',
    title: '분석서비스',

    submenu: [
      {
        submenuTitle: '항만 모니터링',
        submenuUrl: '/portmonitoring',
        newFeatureDate: '2025-03-10',
      },

      {
        submenuTitle: '운임시황 모니터링',
        submenuUrl: '/freight-condition/prediction',
        newFeatureDate: '2025-03-10',
      },
      {
        submenuTitle: '리스크 모니터링',
        submenuUrl: '/riskmonitor/overview',
        newFeatureDate: '2025-03-10',
      },
      {
        submenuTitle: 'Container Tracking',
        submenuUrl: '/container-tracking',
        comingsoon: true,
      },
      {
        submenuTitle: 'Air Tracking',
        submenuUrl: '/air-tracking',
        comingsoon: true,
      },
    ],
  },
  // {
  //   name: 'riskmonitor',
  //   title: '리스크 모니터링',
  //   url: '/riskmonitor/overview',
  //   hasNew: true,
  // },
  // {
  //   name: 'freight-condition',
  //   title: '운임시황 모니터링',
  //   url: '/freight-condition/pattern',
  //   hasNew: true,
  // },
  {
    name: 'plan',
    title: '가격정책',
    url: undefined,

    submenu: [
      {
        submenuTitle: '요금제',
        submenuUrl: '/plan/explain',
        newFeatureDate: '2025-02-10',
      },
      {
        submenuTitle: 'FAQ',
        submenuUrl: '/faq',
      },
    ],
  },
  // {
  //   name: 'custpia',
  //   title: '관세검색',
  //   url: process.env.REACT_APP_CUSTPIA_DOMAIN!,
  //   hasNew: true,
  // },

  {
    name: 'community',
    title: '커뮤니티',
    url: undefined,

    submenu: [
      {
        submenuTitle: '업체찾기',
        submenuUrl: '/company-ad',
      },
      {
        submenuTitle: '뉴스센터',
        submenuUrl: '/community/news-center?page=1&hash=전체',
      },
      // {
      //   submenuTitle: 'Q&A',
      //   submenuUrl: '/community/qna?page=1&hash=전체',
      // },
      {
        submenuTitle: '자료실',
        submenuUrl: '/community/reference-room?page=1&hash=전체',
      },
      // {
      //   submenuTitle: '자유게시판',
      //   submenuUrl: '/community/free?page=1',
      // },
      {
        submenuTitle: '공지사항',
        submenuUrl: '/community/notice?page=1&hash=전체',
      },
    ],
  },
  // {
  //   name: 'warehouse',
  //   title: '창고견적',
  //   url: '/warehouse_quote',
  //   hasNew: false,
  // },
];

// export const SERVICE_LIST: { name: string; url: string }[] = [
//   {
//     name: '출석',
//     url: '/mypage/attendance-check',
//   },
//   {
//     name: '랭킹',
//     url: '/ranking/fame',
//   },
//   {
//     name: '게임',
//     url: '/game',
//   },
// ];

export const QUBE_SERVICE_LIST: QubeServiceListTypes[] = [
  {
    name: '라카이브',
    url: 'https://larchive.simplogis.com',
    icon: navLarchiveIcon,
  },
  {
    name: '커스트피아',
    url: process.env.REACT_APP_CUSTPIA_DOMAIN!,
    icon: navCustopiaIcon,
  },
  // {
  //   name: '큐앤비',
  //   url: undefined,
  //   icon: navQnbIcon,
  // },
];
