import { DateTime } from 'luxon';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { navProfileIcon } from 'assets/image';
import { Fragment, Suspense, useEffect, useRef, useState } from 'react';
import { useApolloClient, useReactiveVar } from '@apollo/client';
import { isLoggedInVar } from 'lib/apollo/apollo';

import useShowProfile from 'hooks/User/useShowProfile';

import useUserChannel from 'hooks/Common/useUserChannel';
import usePageHistory from 'hooks/Common/usePageHistory';
import { useCloseClickOutside } from 'hooks/Common/useCloseClickOutside';

import { showLogin } from 'store/Member';

import { retryLazy } from 'util/LazyUtil';
import { QUBE_SERVICE_LIST, menuList } from './menu';
import imageErrorHandler from 'util/Common/ImageErrorHandler';

import { SubscriptionType } from '__apollo__generated__/globalTypes';
import TrialProfile from 'prev-components/Common/User/Trial';
// import ThemeToggle from 'components/common/toggle/ThemeToggle';
import { DownArrowIcon, GnbCubIcon, LarchiveMainLogo, LoginButtonIcon, NewServiceIcon } from 'assets/images';
import sweetAlert from 'components/common/Alert/sweetAlert';

const Popup = retryLazy(() => import('./Popup'));

function GlobalNavigation() {
  const { history } = usePageHistory();
  const isLoggedIn = useReactiveVar(isLoggedInVar);
  const client = useApolloClient();
  const [getUser, { data: userData, loading }] = useShowProfile();
  const userChannel = useUserChannel();
  const setUnReadMessageTotalCount = useState<number>(0)[1];

  useEffect(() => {
    if (isLoggedIn) {
      (async () => {
        await getUser();
      })();
    }
  }, [getUser, isLoggedIn]);

  // broadcastchannel
  useEffect(() => {
    userChannel.onmessage = event => {
      if (event.data.payload.type === 'login-complete') {
        showLogin(false);

        window.location.reload();
        return;
      } else if (event.data.payload.type === 'LOG OUT') {
        isLoggedInVar(false);
        client.resetStore();
        window.location.reload();
        return;
      }
    };
  }, [userChannel, client]);

  // community popup
  const ref = useRef<HTMLDivElement>(null);
  const [showDropdown, setShowDropdown] = useState('');

  useCloseClickOutside(ref, () => setShowDropdown(''));

  const handleShowDropdown = (name: string) => {
    return setShowDropdown(name);
  };

  // const handleJoinBtn = () => {
  //   showLogin(true);
  //   showSignUp(true);
  // };

  const handleLoginBtn = () => {
    showLogin(true);
  };

  // user profile pop
  const [showUserpop, setShowUserpop] = useState(false);

  // qube pop
  const [showQubepop, setShowQubepop] = useState(false);
  const qubeRef = useRef<HTMLDivElement>(null);
  useCloseClickOutside(qubeRef, () => setShowQubepop(false));
  const handleShowQubepop = () => {
    setShowQubepop(true);
  };

  const handleSubMenu = (url: string) => {
    history.push(url);
  };

  useEffect(() => {
    return () => setUnReadMessageTotalCount(0);
  }, [setUnReadMessageTotalCount]);

  return (
    <Header className="flex-between">
      <ServiceNavigation>
        <Link to="/">
          <LarchiveMainLogo />
        </Link>
        <ServiceList>
          {menuList.map((item, index) => {
            const today = DateTime.now().toMillis();

            const newCategoryDate = item?.newFeatureDate;

            const newCategory =
              newCategoryDate && DateTime.fromISO(newCategoryDate).plus({ day: 14 }).toMillis() >= today;

            if (item.submenu) {
              const newFeatureDateList = item.submenu?.filter(item => item.newFeatureDate);

              const hasNewFeature =
                newFeatureDateList &&
                newFeatureDateList?.find(
                  item =>
                    item?.newFeatureDate && DateTime.fromISO(item.newFeatureDate).plus({ day: 14 }).toMillis() >= today,
                );

              return (
                <ServiceMain key={`${item.title}_${index}`}>
                  <button onClick={() => handleShowDropdown(item.name)} type="button">
                    {item.title}
                    {hasNewFeature && <NewServiceIcon />}
                    <DownArrowIcon />
                  </button>

                  {showDropdown === item.name && (
                    <ServiceSubMenu ref={ref}>
                      {item.submenu?.map(sub => {
                        const today = DateTime.now().toMillis();

                        const newServiceDate = sub?.newFeatureDate;

                        const newService =
                          newServiceDate && DateTime.fromISO(newServiceDate).plus({ day: 14 }).toMillis() >= today;

                        return (
                          <ServiceSubButton
                            comingsoon={Boolean(sub.comingsoon)}
                            onClick={() => {
                              if (sub.comingsoon) {
                                return sweetAlert({
                                  icon: 'info',
                                  title: '해당 서비스 준비 중 입니다.',
                                  description: <div>더 나은 서비스를 제공해 드리기 위해 최선을 다하겠습니다.</div>,
                                });
                              }
                              handleSubMenu(sub.submenuUrl);
                            }}
                            key={sub.submenuUrl}
                          >
                            {sub.submenuTitle}
                            {newService && <NewServiceIcon />}
                          </ServiceSubButton>
                        );
                      })}
                    </ServiceSubMenu>
                  )}
                </ServiceMain>
              );
            }
            return (
              <ServiceMain key={`${item.url}_${index}`}>
                <div
                  onClick={() => {
                    if (item.url) {
                      if (item.title === '관세검색') {
                        window.open(item.url);
                      } else {
                        history.push(item.url);
                      }
                    }
                  }}
                >
                  {item.title}
                </div>
                {newCategory && <NewServiceIcon />}
              </ServiceMain>
            );
          })}
        </ServiceList>
      </ServiceNavigation>
      <ProfileNavigation>
        {isLoggedIn && (
          <TrialService>
            {(userData?.showProfile?.user?.subscription?.productAlias === SubscriptionType.TRIAL_STANDARD ||
              userData?.showProfile?.user?.subscription?.productAlias === SubscriptionType.FREE) && <TrialProfile />}

            <UserProfile>
              <img
                onClick={() => setShowUserpop(true)}
                onError={e => imageErrorHandler(e, navProfileIcon)}
                className="w-full h-full rounded-full cursor-pointer"
                src={userData?.showProfile?.user?.profilePhoto || navProfileIcon}
                alt="profile-icon"
              />
              {!loading && userData?.showProfile?.user?.larchiveProfile.unreadMessageCount !== 0 && <div />}
              {showUserpop && (
                <Suspense fallback={<Fragment></Fragment>}>
                  <Popup setState={setShowUserpop} />
                </Suspense>
              )}
            </UserProfile>
          </TrialService>
        )}{' '}
        {!isLoggedIn && (
          <JoinService>
            {/* <div
              className="px-4 py-2 border-2 border-white border-solid rounded-lg cursor-pointer"
              onClick={handleJoinBtn}
            >
              회원가입
            </div> */}
            <LoginButton onClick={handleLoginBtn}>
              <LoginButtonIcon />
              로그인
            </LoginButton>
          </JoinService>
        )}
        {/* <ThemeToggle /> */}
        <div className="relative">
          <CubContainer onClick={handleShowQubepop}>
            <GnbCubIcon stroke={'#6A6E79'} />
          </CubContainer>
          {showQubepop && (
            <CubePopup
              ref={qubeRef}
              className="flex-cneter"
              //   className="w-56 h-20 shadow-baseShadow
              // rounded-xl absolute top-[60px]
              // flex gap-3 justify-center items-center right-0 z-[100] bg-white"
            >
              {QUBE_SERVICE_LIST.map(qube => (
                <div className="cursor-pointer" onClick={() => qube.url && window.open(qube.url)} key={qube.name}>
                  <div className="flex flex-col items-center ">
                    <img src={qube.icon} alt="qube-icon" />
                    {qube.name}
                  </div>
                </div>
              ))}
            </CubePopup>
          )}
        </div>
      </ProfileNavigation>
    </Header>
  );
}

export default GlobalNavigation;

const CubContainer = styled.div`
  position: relative;

  border-radius: 50%;
  transition: all 0.3s linear;
  padding: 8px;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.Primary.base};
  }
`;

const CubePopup = styled.div`
  width: 224px;
  height: 80px;
  box-shadow: ${({ theme }) => theme.Shadow.shadow_main};
  border-radius: 10px;
  z-index: 100;
  gap: 12px;
  position: absolute;
  top: 40px;
  right: 0;
  background-color: ${({ theme }) => theme.Components.Navigation.bg_main};
`;

const Header = styled.header`
  height: 90px;
  color: ${({ theme }) => theme.Primary.font_main};
  background-color: ${({ theme }) => theme.Components.Navigation.bg_main};
  z-index: ${({ theme }) => theme.ZIndex.header};
  position: sticky;
  top: 0;
  left: 0;
  padding: 0px 29.99px;
  border-bottom: 1px solid ${({ theme }) => theme.Primary.border_primary};
`;

const ServiceNavigation = styled.nav`
  display: flex;
  align-items: center;

  a {
    display: flex;
    align-items: center;
    height: 30px;
    margin-right: 70px;
  }
`;

type ServiceSubButtonProps = {
  comingsoon: boolean;
};

const ServiceSubButton = styled.div<ServiceSubButtonProps>`
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0px 20px;
  font-weight: 500;
  color: ${({ comingsoon, theme }) =>
    comingsoon ? theme.Components.Navigation.font_disable : theme.Components.Navigation.font_main};

  &:hover {
    background-color: ${({ theme }) => theme.Primary.hover_bg};
  }
`;

const ServiceList = styled.ul`
  display: flex;
  align-items: center;
  font-size: ${({ theme }) => theme.Font.medium};
  font-weight: ${({ theme }) => theme.FontWegiht.bold};
  gap: 30px;
`;

const ServiceMain = styled.li`
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 2px;

  button {
    display: flex;
    align-items: center;
    gap: 3px;
  }
`;

const ServiceSubMenu = styled.div`
  /* max-width: 195px; */
  color: ${({ theme }) => theme.Primary.font_main};
  min-width: 122px;
  width: max-content;
  background-color: ${({ theme }) => theme.Components.Navigation.bg_main};
  border-radius: 10px;
  border: ${({ theme }) => theme.Primary.line_main};
  box-shadow: ${({ theme }) => theme.Shadow.shadow_main};
  position: absolute;
  top: calc(100% + 27px);
  left: 50%;
  transform: translateX(-50%);
  z-index: ${({ theme }) => theme.ZIndex.header};
  padding: 5px 0px;
  button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 5px;
    color: ${({ theme }) => theme.Primary.font_main};
    background-color: ${({ theme }) => theme.Components.Navigation.bg_main};

    font-size: ${({ theme }) => theme.Font.small};
    font-weight: ${({ theme }) => theme.FontWegiht.light};
    padding: 15px 20px;

    &:hover {
      color: ${({ theme }) => theme.Primary.font_main};
      background-color: ${({ theme }) => theme.Primary.hover_bg};
    }
  }
`;

const ProfileNavigation = styled.div`
  display: flex;
  align-items: center;
  gap: 26px;
`;

const TrialService = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

const UserProfile = styled.div`
  position: relative;
  cursor: pointer;
  width: 30px;
  height: 30px;
  border-radius: 10px;
`;

const JoinService = styled.div`
  display: flex;
  align-items: center;
`;

const LoginButton = styled.button`
  width: 109px;
  height: 40px;
  border-radius: 74px;
  padding: 12px 20px;
  background-color: ${({ theme }) => theme.Components.Navigation.bg_login_btn};
  color: #fff;
  font-size: ${({ theme }) => theme.Font.small};
  font-weight: ${({ theme }) => theme.FontWegiht.bold};
  display: flex;
  align-items: center;
  gap: 15px;
`;
