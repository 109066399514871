import React from 'react'

const PlanIcon = () => {
  return (
    <div><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path d="M14 1.33331L10.6667 4.66665L8 1.33331L5.33333 4.66665L2 1.33331V9.99998H14V1.33331ZM3.33333 14H12.6667C13.0203 14 13.3594 13.8595 13.6095 13.6095C13.8595 13.3594 14 13.0203 14 12.6666V11.3333H2V12.6666C2 13.0203 2.14048 13.3594 2.39052 13.6095C2.64057 13.8595 2.97971 14 3.33333 14Z" fill="#46B67F"/>
  </svg></div>
  )
}

export default PlanIcon