import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { naverLogoColor, youtubeLogoColor } from 'assets/image';
import usePageHistory from 'hooks/Common/usePageHistory';

export default function MainFooter() {
  const { pathName } = usePageHistory();
  // const handleOpenChat = () => {
  //   window.open(
  //     'https://larchive.simplogis.com/community/notice/638f036b4dfa2eaa84d7c720?page=1&hash=%EC%A0%84%EC%B2%B4',
  //   );
  // };
  return (
    <Footer>
      <FooterContent extrapad={Boolean(pathName.includes('logistic-tools'))}>
        <FooterTop className="flex-between">
          <FooterTopContent>
            {footerMenu.map(link => (
              <Link to={{ pathname: link['url'] }} key={link['name']}>
                {link['name']}
              </Link>
            ))}
          </FooterTopContent>
          <FooterTopContent>
            <Link to={{ pathname: 'https://blog.naver.com/larchive_simplogis' }} target="_blank">
              <img width={32} height={32} src={naverLogoColor} alt="네이버" />
            </Link>
            <Link to={{ pathname: 'https://www.youtube.com/watch?v=WR6xizJGEkw' }} target="_blank">
              <img width={32} height={32} src={youtubeLogoColor} alt="유튜브" />
            </Link>
          </FooterTopContent>
        </FooterTop>
        <FooterSeviceInformation>
          <FoorterServiceParagraph className="flex-between">
            <div>
              <span>라카이브(주)</span>
              <span>대표이사 오현민</span>
              <span>사업자번호: 625-81-00962</span>
              <span>통신판매신고: 제 2024-인천미추홀-0423호</span>
              <span>이메일: help@simplogis.com</span>
            </div>
            <div>
              <span>모든 데이터는 저작권법의 보호를 받은바, 무단전재, 복사-배포 등을 금합니다.</span>
            </div>
          </FoorterServiceParagraph>
          <FoorterServiceParagraph className="flex-between">
            <div>
              <span>본사: 인천시 연수구 컨벤시아대로 204, 인천스타트업파크 인스타1 516호</span>
              <span>서울영업소: 서울특별시 마포구 마포대로 34(도화동, 도원빌딩 9층, 3호실)</span>
            </div>
            <div>
              <span>Copyright © Larchive. All Rights Reserved.</span>
            </div>
          </FoorterServiceParagraph>
        </FooterSeviceInformation>
      </FooterContent>
    </Footer>
  );
}

const Footer = styled.footer`
  width: 100%;
  height: 158px;
  border-top: 2px solid ${({ theme }) => theme.Primary.border_primary};
`;

type FooterContentProps = {
  extrapad?: boolean;
};

const FooterContent = styled.div<FooterContentProps>`
  width: 100%;
  max-width: 1836px;
  padding: ${({ extrapad }) => (extrapad ? '30px 84px' : '30px')};
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  gap: 20px;
`;

const FooterTop = styled.div`
  a {
    color: ${({ theme }) => theme.Components.Footer.font_main};
    font-weight: ${({ theme }) => theme.FontWegiht.extra_bold};
  }

  img {
    border-radius: 50%;
  }
`;

const FooterTopContent = styled.div`
  &:first-child {
    display: flex;
    gap: 17px;
    align-items: center;
  }
  &:last-child {
    display: flex;
    gap: 12px;
    align-items: center;
  }
`;

const FooterSeviceInformation = styled.div`
  color: ${({ theme }) => theme.Components.Footer.font_sub};
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  gap: 14px;
`;

const FoorterServiceParagraph = styled.div`
  span:not(:first-child) {
    ::before {
      content: '|';
      margin: 0px 12px;
      color: ${({ theme }) => theme.Components.Footer.divide};
    }
  }
`;

export const footerMenu: Record<string, string>[] = [
  {
    name: '개인정보처리방침',
    url: '/terms/privacy',
  },
  {
    name: '이용약관',
    url: '/terms/term',
  },

  {
    name: '제휴문의',
    url: '/partnership',
  },
  {
    name: '광고문의',
    url: '/ad-inquiry',
  },
  {
    name: '오픈채팅',
    url: '/community/notice/638f036b4dfa2eaa84d7c720',
  },
];
