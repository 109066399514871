import { createRoot } from 'react-dom/client';

import ReactGA from 'react-ga4';
import './styles/tailwind.css';

import App from './App';

window.Kakao.init(process.env.REACT_APP_KAKAO_JAVASCRIPT_KEY);

ReactGA.initialize(`${process.env.REACT_APP_GOOGLE_ANALYTICS}`);

const root = createRoot(document.getElementById('root')!);

root.render(<App />);
