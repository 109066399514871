import { footerMenu } from '../util';
import { Link } from 'react-router-dom';

export default function WebHomeFooter() {
  const handleOpenChat = () => {
    window.open(
      'https://larchive.simplogis.com/community/notice/638f036b4dfa2eaa84d7c720?page=1&hash=%EC%A0%84%EC%B2%B4',
    );
  };

  return (
    <footer
      className={`w-full min-h-[320px] py-[60px]
    bg-main-page-footer bg-cover
    flex flex-col
    items-center
    text-white

  `}
    >
      <section className="w-[1400px] px-5">
        <div className="flex items-center justify-between mb-5 break-words border-b-[1px] border-gray-300 border-solid pb-6">
          <div className="flex gap-4">
            {footerMenu.slice(0, 3).map(link => (
              <Link className="text-white" to={{ pathname: link['url'] }} key={link['name']}>
                {link['name']}
              </Link>
            ))}
          </div>
          <div className="flex gap-4">
            {footerMenu.slice(3).map(link => (
              <Link className="text-white" to={{ pathname: link['url'] }} key={link['name']}>
                {link['name']}
              </Link>
            ))}
            <button className="text-white text-[16px]" onClick={handleOpenChat}>
              오픈채팅
            </button>
          </div>
        </div>
        <div className="flex items-center justify-between py-6">
          <div>
            <span>라카이브(주)</span>
            <span className='before:content-["|"] before:text-gray-300 before:mx-3 '>대표이사 오현민</span>
            <span className='before:content-["|"] before:text-gray-300 before:mx-3 '>사업자번호: 625-81-00962</span>
            <span className='before:content-["|"] before:text-gray-300 before:mx-3 '>
              통신판매신고: 제 2024-인천미추홀-0423호
            </span>
            <span className='before:content-["|"] before:text-gray-300 before:mx-3 '>이메일: help@simplogis.com</span>
            {/*<span className='before:content-["|"] before:text-gray-300 before:mx-3 '>전화번호: 032-710-7102</span>*/}
          </div>
          <div className="flex gap-3">
            <Link to={{ pathname: 'https://blog.naver.com/larchive_simplogis' }} target="_blank">
              <div className="bg-naver-icon-gray w-[50px] aspect-square"></div>
            </Link>
            <Link target="_blank" to={{ pathname: 'https://www.youtube.com/watch?v=WR6xizJGEkw' }}>
              <div
                className="bg-youtube-icon-gray
              w-[50px] aspect-square
            "
              ></div>
            </Link>
          </div>
        </div>
      </section>
      <section className="flex justify-between items-center w-[1400px] px-5">
        <div className="flex flex-col gap-3">
          <p>본사: 인천시 연수구 컨벤시아대로 204, 인천스타트업파크 인스타1 516호</p>
          <p>서울영업소: 서울특별시 마포구 마포대로 34(도화동, 도원빌딩 9층, 3호실)</p>
        </div>
        <p>모든 데이터는 저작권법의 보호를 받은바, 무단전재, 복사-배포 등을 금합니다.</p>
        <p>Copyright © Larchive. All Rights Reserved.</p>
      </section>
    </footer>
  );
}
