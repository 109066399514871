import { createContext } from 'react';
import { ThemeConfigType } from 'styles/theme/theme';

const AppContext = createContext<{
  theme: {
    themeConfig: ThemeConfigType;
    _handleThemeConfig: (param: ThemeConfigType) => void;
  };
}>({
  theme: {
    themeConfig: 'light',
    _handleThemeConfig: _ => {},
  },
});

export default AppContext;
