import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ChangeEvent, Fragment, Suspense, useEffect, useState } from 'react';
import styled from 'styled-components';

import Member from 'views/Member';
import useLayout from 'components/layouts/hooks/Controller/useLayout';

import { getCookie } from 'util/cookies';

import useMemoCookies from 'hooks/Common/useMemoCookies';
import WebHomeFooter from './FooterV2/WebHomeFooter';
import MainFooter from './FooterV2';
import GlobalNavigation from './GlobalNavigation';
import LogisticNavigationAdaptive from './LogisticNavigation';
import CookieConsent from 'components/popup/CookieConsent';
import { UpdateNoticePop } from 'components/popup/Notice/20241210Update';
import sweetAlert from 'components/common/Alert/sweetAlert';

interface WebMainLayoutProps extends RouteComponentProps {
  children: React.ReactNode;
}

const NO_SHO_NOTICE_COOKIE = 'release-notice-20250121';

const NO_SHOW_SERVICE_POP = 'service-notice-pop-20250205';

function WebMainLayout({ children }: WebMainLayoutProps) {
  const [showCookieConsented, setShowCookieConsented] = useState(false);
  const { showLoginVar, topClick, pathName, history } = useLayout();
  const memoCookies = useMemoCookies();

  const [showNotice, setShowNotice] = useState(false);

  useEffect(() => {
    if (pathName.includes('/logistic-tools/congestion-in-logistics/country-terminal-congestion')) {
      history.replace('/portmonitoring');
    }
  }, [pathName, history]);

  useEffect(() => {
    const hasConsent = getCookie('cookie-consent-agree');

    if (hasConsent) {
      setShowCookieConsented(false);
    } else {
      setShowCookieConsented(true);
    }
  }, [setShowCookieConsented, pathName]);

  useEffect(() => {
    const notShow = memoCookies.get(NO_SHO_NOTICE_COOKIE);
    if (notShow || pathName.includes('plan')) {
      setShowNotice(false);
    } else {
      setShowNotice(true);
    }
  }, [memoCookies, setShowNotice, pathName]);

  // useEffect(() => {
  //   const has = getCookie(NO_SHOW_SERVICE_POP);
  //   if (has) return;
  //   sweetAlert({
  //     icon: 'info',
  //     title: '[공지] 서비스 점검 예정 안내',
  //     description: (
  //       <div>
  //         <div>2025년 02월 04일 화요일 18:00 부터 21:00 까지</div>
  //         <div>서비스 점검으로 이용이 원활하지 않을 수 있습니다.</div>
  //         <div>이용에 불편을 드려 대단히 죄송합니다.</div>
  //         <div>감사합니다.</div>
  //         <div className="flex items-center justify-center gap-5">
  //           <div
  //             // onClick={() => {
  //             //   memoCookies.set(NO_SHOW_SERVICE_POP, true, {
  //             //     maxAge: 24 * 60 * 60,
  //             //   });
  //             // }}

  //             className="flex justify-center gap-2 mt-5 cursor-pointer"
  //           >
  //             <input
  //               onChange={(e: ChangeEvent<HTMLInputElement>) => {
  //                 const {
  //                   currentTarget: { checked },
  //                 } = e;

  //                 if (checked) {
  //                   memoCookies.set(NO_SHOW_SERVICE_POP, true, {
  //                     maxAge: 24 * 60 * 60 * 7,
  //                     path: '/',
  //                   });
  //                 } else {
  //                   memoCookies.remove(NO_SHOW_SERVICE_POP, {
  //                     path: '/',
  //                   });
  //                 }
  //               }}
  //               id={NO_SHOW_SERVICE_POP + '1'}
  //               type="checkbox"
  //               className="w-[20px] aspect-square"
  //             />
  //             <label className="mt-1 cursor-pointer" htmlFor={NO_SHOW_SERVICE_POP + '1'}>
  //               다시 보지않기
  //             </label>
  //           </div>
  //           {/* <div
  //             onClick={() => {
  //               memoCookies.set(NO_SHOW_SERVICE_POP, true, {
  //                 maxAge: 24 * 60 * 60 * 7,
  //               });
  //             }}
  //             className="flex justify-center gap-2 mt-5 cursor-pointer"
  //           >
  //             <input id={NO_SHOW_SERVICE_POP + '7'} type="checkbox" />
  //             <label className="cursor-pointer" htmlFor={NO_SHOW_SERVICE_POP + '7'}>
  //               일주일동안 보지 않기
  //             </label>
  //           </div> */}
  //         </div>
  //       </div>
  //     ),
  //   });
  // }, []);

  return (
    <Wrapper>
      <Main>
        {showNotice && <UpdateNoticePop cookieName={NO_SHO_NOTICE_COOKIE} close={setShowNotice} />}

        <GlobalNavigation />
        <MainContainer>
          {pathName.includes('logistic-tools') && <LogisticNavigationAdaptive />}

          {children}
        </MainContainer>
        {pathName === '/' ? <WebHomeFooter /> : <MainFooter />}
        {showCookieConsented && <CookieConsent setShowCookieConsented={setShowCookieConsented} />}
      </Main>
      {showLoginVar && (
        <Suspense fallback={<Fragment></Fragment>}>
          <Member />
        </Suspense>
      )}

      <TopBtn>
        <div onClick={topClick}>Top</div>
      </TopBtn>
    </Wrapper>
  );
}

export default withRouter(WebMainLayout);

const TopBtn = styled.div`
  width: 50px;
  aspect-ratio: 1 / 1;
  position: fixed;
  bottom: 100px;
  right: 30px;
  div {
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.Primary.base};
    opacity: 0.2;
    color: ${({ theme }) => theme.Primary.font_select_inverse};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    cursor: pointer;
    &:hover {
      opacity: 1;
    }
  }
`;

const Wrapper = styled.div`
  min-width: 1600px;
  width: 100%;
  background-color: ${({ theme }) => theme.Primary.bg_main};
`;

const Main = styled.main`
  height: 100%;
  position: relative;
`;

const MainContainer = styled.div`
  min-height: calc(100vh - 218px);
  display: flex;
`;

// useEffect(() => {
//   sweetAlert({
//     icon: 'info',
//     title: '[서비스 점검] 서비스 점검 안내',
//     description: (
//       <div>
//         <div>금일 19시부터 21시까지</div>
//         <div>서비스 점검으로 이용이 원활하지 않을 수 있습니다.</div>
//         <div>이용에 불편을 드려 대단히 죄송합니다.</div>
//         <div>감사합니다.</div>
//       </div>
//     ),
//   });
// }, []);

// useEffect(() => {
//   const has = getCookie(NO_SHOW_SERVICE_POP);
//   if (has) return;

//   sweetAlert({
//     icon: 'info',
//     title: '[공지] 팀 결제 업데이트 지연 안내',
//     width: '640px',
//     description: (
//       <div className="text-[17px] flex flex-col gap-1 text-black">
//         <div>팀 결제 배포 중 결제 서비스 사에서 </div>
//         <div>에러를 발견하여 서비스 출시가 지연되었습니다.</div>
//         <div>불편을 끼쳐드려 고객님들께 깊은 사과를 드립니다.</div>
//         <div>빠른 시일 내에 팀 결제 서비스를 제공할 수 있도록 하겠습니다.</div>
//         <div>감사합니다.</div>
//         <div className="flex items-center justify-center gap-5">
//           <div
//             // onClick={() => {
//             //   memoCookies.set(NO_SHOW_SERVICE_POP, true, {
//             //     maxAge: 24 * 60 * 60,
//             //   });
//             // }}

//             className="flex justify-center gap-2 mt-5 cursor-pointer"
//           >
//             <input
//               onChange={(e: ChangeEvent<HTMLInputElement>) => {
//                 const {
//                   currentTarget: { checked },
//                 } = e;

//                 if (checked) {
//                   memoCookies.set(NO_SHOW_SERVICE_POP, true, {
//                     maxAge: 24 * 60 * 60 * 7,
//                     path: '/',
//                   });
//                 } else {
//                   memoCookies.remove(NO_SHOW_SERVICE_POP, {
//                     path: '/',
//                   });
//                 }
//               }}
//               id={NO_SHOW_SERVICE_POP + '1'}
//               type="checkbox"
//               className="w-[20px] aspect-square"
//             />
//             <label className="mt-1 cursor-pointer" htmlFor={NO_SHOW_SERVICE_POP + '1'}>
//               다시 보지않기
//             </label>
//           </div>
//           {/* <div
//             onClick={() => {
//               memoCookies.set(NO_SHOW_SERVICE_POP, true, {
//                 maxAge: 24 * 60 * 60 * 7,
//               });
//             }}
//             className="flex justify-center gap-2 mt-5 cursor-pointer"
//           >
//             <input id={NO_SHOW_SERVICE_POP + '7'} type="checkbox" />
//             <label className="cursor-pointer" htmlFor={NO_SHOW_SERVICE_POP + '7'}>
//               일주일동안 보지 않기
//             </label>
//           </div> */}
//         </div>
//       </div>
//     ),
//   });
// }, [memoCookies, pathName]);
