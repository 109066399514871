import styled, { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';

import gothamMedium from 'assets/font/GothamMedium/Gotham-Medium.otf';
import pretendardRegularWoff2 from 'assets/font/Pretendard/Pretendard-Regular.subset.woff2';
import pretendardRegularWoff from 'assets/font/Pretendard/Pretendard-Regular.subset.woff';
import pretendardMediumWoff2 from 'assets/font/Pretendard/Pretendard-Medium.subset.woff2';
import pretendardMediumWoff from 'assets/font/Pretendard/Pretendard-Medium.subset.woff';
import pretendardSemiBoldWoff2 from 'assets/font/Pretendard/Pretendard-Bold.subset.woff2';
import pretendardSemiBoldWoff from 'assets/font/Pretendard/Pretendard-Bold.subset.woff';
import pretendardBoldWoff2 from 'assets/font/Pretendard/Pretendard-Bold.subset.woff2';
import pretendardBoldWoff from 'assets/font/Pretendard/Pretendard-Bold.subset.woff';

const GlobalStyle = createGlobalStyle`

  @font-face {
    font-family: GothamMedium;
    font-weight: 500;
    font-display: swap;
    src:
    local("Gotham Medium"),
    url(${gothamMedium}) format('opentype')
  }

  /* Pretendard */
  @font-face {
    font-family: Pretendard;
    font-weight: 400;
    font-display: swap;
    src:
    local("Pretendard Regular"),
    url(${pretendardRegularWoff2}) format('woff2'),
    url(${pretendardRegularWoff}) format('woff')
    ;
  }

  @font-face {
    font-family: Pretendard;
    font-weight: 500;
    font-display: swap;
    src:
    local("Pretendard Medium"),
    url(${pretendardMediumWoff2}) format('woff2'),
    url(${pretendardMediumWoff}) format('woff')
    ;
  }

  @font-face {
    font-family: Pretendard;
    font-weight: 600;
    font-display: swap;
    src:
    local("Pretendard SemiBold"),
    url(${pretendardSemiBoldWoff2}) format('woff2'),
    url(${pretendardSemiBoldWoff}) format('woff'),
    ;
  }

  @font-face {
    font-family: Pretendard;
    font-weight: 700;
    font-display: swap;
    src:
    local("Pretendard Bold"),
    url(${pretendardBoldWoff2}) format('woff2'),
    url(${pretendardBoldWoff}) format('woff')
    ;
  }

  ${reset}



  * {
    box-sizing: border-box;

    .flex-cneter  {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .flex-between {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .riskmonitor-checkbox-icon {
    width: 22px;
    height: 22px;
    border-radius: 3px;
    border: 1px solid ${({ theme }) => theme.Primary.border_primary};
    background-color: ${({ theme }) => theme.Primary.bg_primary};
  }
  }

  /* html {
    font-size: 62.5%;
  } */


  body
  {
    /* width: 100%;
    height: 100%; */
    /* box-sizing: border-box; */
    /* overflow-x: hidden; */
    font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
    color: ${({ theme }) => theme.Primary.font_main};
    background-color: ${({ theme }) => theme.Primary.bg_primary};
    letter-spacing: ${({ theme }) => theme.Primary.letter_spacing};
    font-size: 15px;
  }
  input.highcharts-range-selector {
    color: red;
  }
  .highcharts-range-selector-buttons {
    .highcharts-label {
      text {
        display: none;
      }
    }
  }

  a {
    text-decoration: none;
    color: black;
  }


  li {
    list-style: none;
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input, textarea {
    font-family: inherit;
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
    color: ${({ theme }) => theme.Primary.font_main};

  }

  div.riskmonitor_subevent_name {
    font-size: 14px;
    font-weight: 600;
    width: fit-content;
    display: inline-block;
    padding: 6px 10px;
    border-radius: 4px;
    line-height: 15.6px;
    color: ${({ theme }) => theme.Primary.font_select_inverse};
    border: 1px solid ${({ theme }) => theme.Primary.border_primary};
    background-color: ${({ theme }) => theme.Primary.bg_tab_select};
  }
`;

export default GlobalStyle;

// prev css

export const GlobalBtn = styled.button`
  padding: 14px 20px;
  background-color: ${({ theme }) => theme.Primary.base};
  border-radius: 20px;
  align-self: center;
  font-family: inherit;
  color: ${({ theme }) => theme.Primary.font_main};

  white-space: nowrap;
  transition: all 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);
  &:hover {
    background-color: rgba(20, 182, 114, 1);
  }
  &.mypage-delete {
    padding: 8px 13px;
  }
  &.board--write__button {
    width: 100%;
    height: 50px;
    font-size: 16px;
    line-height: 1.35714286;
  }
  &.board--submit__btn {
    width: 100%;
    height: 50px;
    font-size: 16px;
    margin-left: 10px;
  }
  &.reply--submit__btn {
    width: 100%;
    height: 50px;
    font-size: 16px;
  }
  &.comment--submit__btn {
    width: 60px;
    height: 35px;
    background-color: ${({ theme }) => theme.Primary.bg_main};
    color: ${({ theme }) => theme.Primary.font_main};

    border: 1px solid ${({ theme }) => theme.Primary.line_main};
    padding: 0;
  }
`;

// 공통 컴포넌트 버튼 정의

export const MainTab = styled.button`
  display: inline-flex;
  min-width: 144px;
  padding: 14px;
  justify-content: center;
  align-items: center;
  gap: 5px;
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border-radius: 8px;
  font-family: Pretendard;

  &.default__btn {
    color: ${({ theme }) => theme.Components.Button.MainTab?.default_text_color};
    border: 1px solid ${({ theme }) => theme.Components.Button.MainTab.default_border_color};
    background: ${({ theme }) => theme.Components.Button.MainTab.default_bg_color};
  }

  &.selected__btn {
    color: ${({ theme }) => theme.Components.Button.MainTab?.selected_text_color};
    border: 1px solid ${({ theme }) => theme.Components.Button.MainTab.selected_border_color};
    background: ${({ theme }) => theme.Components.Button.MainTab.selected_bg_color};
  }
`;

export const SquareTab = styled.button`
  display: inline-flex;
  min-width: 144px;
  padding: 14px;
  justify-content: center;
  align-items: center;
  gap: 5px;
  color: var(--132-d-46, #132d46);
  font-family: Pretendard;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 8px;

  &.default__btn {
    color: ${({ theme }) => theme.Components.Button.SquareTab?.default_text_color};
    border: 1px solid ${({ theme }) => theme.Components.Button.SquareTab.default_border_color};
    background: ${({ theme }) => theme.Components.Button.SquareTab.default_bg_color};
  }

  &.selected__btn {
    color: ${({ theme }) => theme.Components.Button.SquareTab?.selected_text_color};
    border: 1px solid ${({ theme }) => theme.Components.Button.SquareTab.selected_border_color};
    background: ${({ theme }) => theme.Components.Button.SquareTab.selected_bg_color};
  }
`;

export const SquareMiniTab = styled.button`
  display: flex;
  min-width: 70px;
  max-width: 1920px;
  padding: 6px 15px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: Pretendard;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 4px;

  &.default__btn {
    color: ${({ theme }) => theme.Components.Button.SquareMiniTab?.default_text_color};
    border: 1px solid ${({ theme }) => theme.Components.Button.SquareMiniTab.default_border_color};
    background: ${({ theme }) => theme.Components.Button.SquareMiniTab.default_bg_color};
  }

  &.selected__btn {
    color: ${({ theme }) => theme.Components.Button.SquareMiniTab.selected_text_color};
    border: 1px solid ${({ theme }) => theme.Components.Button.SquareMiniTab.selected_border_color};
    background: ${({ theme }) => theme.Components.Button.SquareMiniTab.selected_bg_color};
  }
`;
interface MenuListItemProps {
  current?: string;
}

export const UnderBarTab = styled.button<MenuListItemProps>`
  display: flex;
  padding: 15px 10px;
  align-items: center;
  gap: 10px;
  font-family: Pretendard;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  position: relative;

  &.default__btn {
    color: ${({ theme }) => theme.Components.Button.UnderBarTab?.default_text_color};
    border-bottom: 1px solid ${({ theme }) => theme.Components.Button.UnderBarTab.default_border_color};
    background: ${({ theme }) => theme.Components.Button.UnderBarTab.default_bg_color};
  }

  &.selected__btn {
    color: ${({ theme }) => theme.Components.Button.UnderBarTab.selected_text_color};
    border-bottom: 3px solid ${({ theme }) => theme.Components.Button.UnderBarTab.selected_border_color};
    background: ${({ theme }) => theme.Components.Button.UnderBarTab.selected_bg_color};
  }
`;
