import { useEffect, useState } from 'react';
import { ApolloProvider } from '@apollo/client';

import { client } from 'lib/apollo/apollo';
import { ThemeProvider } from 'styled-components';
import { RecoilRoot } from 'recoil';

import './styles/tailwind.css';

import GlobalStyle from 'styles/GlobalStyle';

import LocalErrorBoundary from 'prev-components/Common/ErrorBoundary/LocalErrorBoundary';

import MainRouter from 'router/MainRouter';
import { loadErrorMessages, loadDevMessages } from '@apollo/client/dev';

import { defaultTheme, darkTheme, ThemeConfigType } from 'styles/theme/theme';
import AppContext from 'context';

function App() {
  const [themeConfig, setThemeConfig] = useState<ThemeConfigType>('light');

  useEffect(() => {
    window.process = {
      ...window.process,
    };
  }, []);

  if (process.env.NODE_ENV === 'production' && window.location.protocol === 'http:') {
    window.location.href = window.location.href.replace('http:', 'https:');
  }

  if (process.env.NODE_ENV !== 'production') {
    // Adds messages only in a dev environment
    loadDevMessages();
    loadErrorMessages();
  }

  const _handleThemeConfig = (theme: ThemeConfigType) => {
    window.localStorage.setItem('theme', theme);

    setThemeConfig(theme);
  };

  useEffect(() => {
    const currentTheme = window.localStorage.getItem('theme');

    setThemeConfig((currentTheme as ThemeConfigType | null) || 'light');
  }, [setThemeConfig]);

  return (
    <AppContext.Provider
      value={{
        theme: {
          themeConfig,
          _handleThemeConfig,
        },
      }}
    >
      <ApolloProvider client={client}>
        <RecoilRoot>
          <LocalErrorBoundary>
            <ThemeProvider theme={themeConfig === 'dark' ? darkTheme : defaultTheme}>
              <GlobalStyle />

              <MainRouter />
            </ThemeProvider>
          </LocalErrorBoundary>
        </RecoilRoot>
      </ApolloProvider>
    </AppContext.Provider>
  );
}

export default App;
