import { useLazyQuery } from '@apollo/client';

import { Que_Get_Profile, Que_Get_ProfileVariables } from '__apollo__generated__/Que_Get_Profile';
import { GET_PROFILE } from 'graphql/userinfoQuery';
import { adminRoleListVar, userIdVar } from 'store/mypageState';

import { userProfileVar } from 'store/UserStore';
import { useSetRecoilState } from 'recoil';
import { userProfileAtom } from 'atom/user';

const useShowProfile = () => {
  const setUserValue = useSetRecoilState(userProfileAtom);
  return useLazyQuery<Que_Get_Profile, Que_Get_ProfileVariables>(GET_PROFILE, {
    variables: {
      input: {
        unreadMessageCountRequired: true,
      },
    },
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'ignore',

    // skip: !isLoggedInVar() ? true : false,
    onCompleted: (data: Que_Get_Profile) => {
      if (!data) {
        return;
      }
      const {
        showProfile: { user },
      } = data;

      setUserValue(user);
      userIdVar(user?.id || 0);
      adminRoleListVar(user?.adminRoleList);
      userProfileVar(user);
    },
  });
};

export default useShowProfile;
